import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import './popUp.scss'

function PopUp(args) {
  const [modal, setModal] = useState(true); // Set modal to true by default

  // Function to toggle the modal
  const toggle = () => setModal(!modal);
  // const modalStyles = {
  //   fontFamily: 'Arial, sans-serif !important', // Example font family
  // };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} {...args}>
        <ModalHeader toggle={toggle} ><div className='warning-modal-title'>
          PLEASE NOTE</div>
          </ModalHeader>
        <ModalBody>
        We are currently on a DO NOT CONSUME advisory for elevated levels of arsenic. Please contact our office for more information.</ModalBody>
      </Modal>
    </div>
  );
}

export default PopUp;
